<template>
    <div class="message-list">
      <h2>Messages</h2>
      <ul>
        <li v-for="message in messages" :key="message.id" :class="{ unread: !message.read }">
          <div class="message-header">
            <strong>{{ message.name }}</strong>
            <span>{{ formatDate(message.timestamp) }}</span>
          </div>
          <div>{{ message.email }}</div>
          <p>{{ message.message }}</p>
          <button v-if="!message.read" @click="markAsRead(message.id)">Mark as Read</button>
        </li>
      </ul>
    </div>
  </template>
  
  <script>
  import { computed, onMounted } from 'vue';
  import { useStore } from 'vuex';
  
  export default {
    name: 'MessageList',
    setup() {
      const store = useStore();
  
      const messages = computed(() => store.getters.messages);
  
      onMounted(() => {
        store.dispatch('fetchMessages');
      });
  
      const formatDate = (timestamp) => {
        if (timestamp && timestamp.toDate) {
          return timestamp.toDate().toLocaleString();
        }
        return 'N/A';
      };
  
      const markAsRead = async (messageId) => {
        await store.dispatch('markMessageAsRead', messageId);
      };
  
      return {
        messages,
        formatDate,
        markAsRead,
      };
    },
  };
  </script>
  
  <style scoped>
  .message-list ul {
    list-style-type: none;
    padding: 0;
  }
  
  .message-list li {
    border: 1px solid #ddd;
    margin-bottom: 10px;
    padding: 10px;
    border-radius: 5px;
  }
  
  .message-list li.unread {
    border-left: 5px solid var(--accent-color);
  }
  
  .message-header {
    display: flex;
    justify-content: space-between;
    margin-bottom: 5px;
  }
  
  button {
    background-color: var(--accent-color);
    color: var(--primary-color);
    border: none;
    padding: 5px 10px;
    border-radius: 5px;
    cursor: pointer;
  }
  </style>