<template>
  <div class="modal-overlay" @click.self="close">
    <div class="modal-content">
      <button class="close-btn" @click="close" aria-label="Close modal">×</button>

      <h2>{{ isViewMode ? title : (isEditing ? `Edit "${title}"` : 'Add New Blog Post') }}</h2>

      <div v-if="isViewMode" class="view-mode">
        <div class="blog-content" v-html="processedContent"></div>
      </div>

      <div v-else class="edit-mode">
        <input v-model="title" placeholder="Enter post title" class="post-title-input" />
        <input v-model="author"  placeholder="Enter author name" class="post-author-input" />
        <input v-model="date" type="date" class="post-date-input" />
        <div class="editor-wrapper">
          <div class="editor-menu-bar">
            <button @click="editor?.chain().focus().toggleBold().run()" :class="{ 'is-active': editor?.isActive('bold') }">
              <span class="icon">B</span>
            </button>
            <button @click="editor?.chain().focus().toggleItalic().run()" :class="{ 'is-active': editor?.isActive('italic') }">
              <span class="icon">I</span>
            </button>
            <button @click="editor?.chain().focus().toggleStrike().run()" :class="{ 'is-active': editor?.isActive('strike') }">
              <span class="icon">S</span>
            </button>
            <button @click="editor?.chain().focus().toggleHeading({ level: 1 }).run()" :class="{ 'is-active': editor?.isActive('heading', { level: 1 }) }">
              <span class="icon">H1</span>
            </button>
            <button @click="editor?.chain().focus().toggleHeading({ level: 2 }).run()" :class="{ 'is-active': editor?.isActive('heading', { level: 2 }) }">
              <span class="icon">H2</span>
            </button>
            <button @click="editor?.chain().focus().toggleBulletList().run()" :class="{ 'is-active': editor?.isActive('bulletList') }">
              <span class="icon">•</span>
            </button>
            <button @click="editor?.chain().focus().toggleOrderedList().run()" :class="{ 'is-active': editor?.isActive('orderedList') }">
              <span class="icon">1.</span>
            </button>
            <button @click="triggerImageUpload" title="Insert Image">
              <span class="icon">🖼️</span>
            </button>
            <button @click="addLink" :class="{ 'is-active': editor?.isActive('link') }" title="Insert Link">
              <span class="icon">🔗</span>
            </button>
          </div>
          <editor-content :editor="editor" />
        </div>
        <input
        ref="imageInput"
          type="file"
          @change="uploadImage"
          accept="image/*"
          style="display: none;"
        />
      </div>

      <div class="modal-actions">
        <button v-if="!isViewMode" @click="save" class="save-btn">Save</button>
        <button v-if="!isViewMode" @click="deletePost" class="delete-btn">Delete Post</button>
        <button v-if="!isViewMode" @click="close" class="cancel-btn">Cancel</button>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, onMounted, onBeforeUnmount, watch, computed } from 'vue';
import { useEditor, EditorContent } from '@tiptap/vue-3';
import StarterKit from '@tiptap/starter-kit';
import Image from '@tiptap/extension-image';
import Link from '@tiptap/extension-link';
import YouTube from '@tiptap/extension-youtube';
import { useStore } from 'vuex';

export default {
  name: 'BlogPostModal',
  components: {
    EditorContent,
  },
  props: {
    post: {
      type: Object,
      default: null,
    },
    user: {
      type: Object,
      default: null,
    },
  },
  emits: ['close', 'save'],
  setup(props, { emit }) {
    const store = useStore();
    const title = ref(props.post?.title || '');
    const content = ref(props.post?.content || '');
    const author = ref(props.post?.author || '');
    const date = ref(props.post?.date ? new Date(props.post.date.seconds * 1000).toISOString().split('T')[0] : '');
    const isEditing = ref(!!props.post);
    const isViewMode = ref(!props.user);
    const imageInput = ref(null);
    const editor = useEditor({
      content: props.post?.content || '',
      extensions: [
        StarterKit,
        Image,
        Link.configure({
          openOnClick: false,
        }),
        YouTube.configure({
          controls: true,
          nocookie: true,
          modestBranding: true,
          HTMLAttributes: {
            class: 'youtube-embed',
          },
        }),
      ],
      editable: !isViewMode.value,
    });

    const convertYouTubeLinks = (content) => {
      const youtubeRegex = /<a[^>]*href="https?:\/\/(?:www\.)?youtube\.com\/watch\?v=([a-zA-Z0-9_-]+)"[^>]*>.*?<\/a>|https?:\/\/(?:www\.)?youtube\.com\/watch\?v=([a-zA-Z0-9_-]+)/g;
      return content.replace(youtubeRegex, (match, linkVideoId, plainVideoId) => {
        const videoId = linkVideoId || plainVideoId;
        return `<iframe src="https://www.youtube.com/embed/${videoId}" frameborder="0" allowfullscreen></iframe>`;
      });
    };

    const processedContent = computed(() => {
      if (!content.value) return '';
      let processedContent = convertYouTubeLinks(content.value);
      processedContent = processedContent.replace(/<img/g, '<img style="max-width: 100%;"');
      return processedContent;
    });

    watch(() => props.post, (newPost) => {
      if (newPost) {
        title.value = newPost.title || '';
        content.value = newPost.content || '';
        author.value = newPost.author || '';
        date.value = newPost.date ? new Date(newPost.date.seconds * 1000).toISOString().split('T')[0] : '';
        const convertedContent = convertYouTubeLinks(content.value);
        editor.value?.commands.setContent(convertedContent);
      }
    });

    watch(() => props.user, (newUser) => {
      isViewMode.value = !newUser;
    });

    const addImage = () => {
      const url = window.prompt('Enter the URL of the image:');
      if (url) {
        editor.value.chain().focus().setImage({ src: url }).run();
      }
    };

    const addLink = () => {
      const url = window.prompt('Enter the URL:');
      if (url) {
        editor.value.chain().focus().setLink({ href: url }).run();
      }
    };

    const triggerImageUpload = () => {
      imageInput.value.click();
    };

    const uploadImage = async (event) => {
      const file = event.target.files[0];
      if (file) {
        try {
          const url = await store.dispatch('uploadBlogImage', file);
          editor.value?.chain().focus().setImage({ src: url }).run();
        } catch (error) {
          console.error('Error uploading image:', error);
          // Handle error (e.g., show error message to user)
        }
      }
    };

    const save = async () => {
      const postData = {
        title: title.value,
        content: editor.value.getHTML(),
        author: author.value,
        date: date.value ? new Date(date.value) : null,
      };

      try {
        if (isEditing.value) {
          const updatedPost = await store.dispatch('updateBlogPost', { ...props.post, ...postData });
          emit('save', updatedPost);
        } else {
          const newPost = await store.dispatch('addBlogPost', postData);
          emit('save', newPost);
        }
        close();
      } catch (error) {
        console.error('Error saving blog post:', error);
        // Handle error (e.g., show error message to user)
      }
    };

    const close = () => {
      emit('close');
    };

    const deletePost = async () => {
      if (confirm('Are you sure you want to delete this post?')) {
        try {
          await store.dispatch('deleteBlogPost', props.post.id);
          emit('close');
        } catch (error) {
          console.error('Error deleting blog post:', error);
          // Handle error (e.g., show error message to user)
        }
      }
    };

    onMounted(() => {
      document.body.style.overflow = 'hidden';
      if (props.post?.content) {
        const convertedContent = convertYouTubeLinks(props.post.content);
        editor.value?.commands.setContent(convertedContent);
      }
    });

    onBeforeUnmount(() => {
      document.body.style.overflow = 'auto';
    });

    return {
      title,
      content,
      processedContent,
      author,
      date,
      editor,
      isEditing,
      isViewMode,
      save,
      close,
      addImage,
      addLink,
      imageInput,
      triggerImageUpload,
      uploadImage,
      deletePost,
    };
  },
};
</script>

<style scoped>
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modal-content {
  position: relative; /* Add this line */
  background-color: white;
  padding: 2rem;
  border-radius: 8px;
  width: 80%;
  max-width: 800px;
  max-height: 80vh;
  overflow-y: auto;
}

.close-btn {
  position: absolute;
  top: 15px;
  right: 15px;
  background: none;
  border: none;
  font-size: 2em;
  cursor: pointer;
  color: #333;
  z-index: 10;
}

.view-mode {
  padding-left: 20px;
  padding-right: 20px;
}

.view-mode h3 {
  margin-bottom: 20px;
}

.blog-content {
  line-height: 1.6;
}

.edit-btn {
  background-color: #4CAF50;
  color: white;
}

.post-title-input {
  width: 100%;
  font-size: 1.2em;
  padding: 0.5em;
  margin-bottom: 1em;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.post-author-input {
  width: 100%;
  font-size: 1.2em;
  padding: 0.5em;
  margin-bottom: 1em;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.post-date-input {
  width: 100%;
  font-size: 1.2em;
  padding: 0.5em;
  margin-bottom: 1em;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.editor-wrapper {
  border: 1px solid #ccc;
  border-radius: 4px;
  margin-bottom: 1em;
}

.editor-menu-bar {
  padding: 0.5em;
  border-bottom: 1px solid #ccc;
  display: flex;
  flex-wrap: wrap;
  gap: 0.5em;
}

.editor-menu-bar button {
  padding: 0.3em 0.6em;
  border: 1px solid #ccc;
  background-color: #f0f0f0;
  border-radius: 4px;
  cursor: pointer;
  font-size: 0.9em;
}

.editor-menu-bar button.is-active {
  background-color: #e0e0e0;
  font-weight: bold;
}

.editor-content {
  padding: 1em;
  min-height: 200px;
}

.modal-actions {
  display: flex;
  justify-content: flex-end;
  gap: 1em;
}

.save-btn, .cancel-btn {
  padding: 0.5em 1em;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 1em;
}

.save-btn {
  background-color: var(--accent-color);
  color: white;
}

.cancel-btn {
  background-color: #ccc;
  color: black;
}

.delete-btn {
  background-color: #dc3545;
  color: white;
  padding: 0.5em 1em;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 1em;
}

/* Styles for the Tiptap editor */
:deep(.ProseMirror) {
  min-height: 150px;
  outline: none;
  padding: 1em;
}

:deep(.ProseMirror p) {
  margin: 0 0 1em 0;
}

:deep(.ProseMirror img) {
  max-width: 100%;
  height: auto;
  display: block;
  margin: 1em 0;
}

</style>
