<template>
  <div class="gallery-container">
    <FilterSortControls
      :paintings="paintings"
      @filter="handleFilter"
      @sort="handleSort"
    />
    <div v-if="isLoading" class="loading">Loading paintings...</div>
    <div v-else-if="displayedPaintings.length === 0 && !user" class="no-paintings">No paintings found.</div>
    <div v-else class="gallery">
      <!-- Add Image Card -->
      <div v-if="user" class="painting add-image-card" @click="openAddModal">
        <div class="add-image-content">
          <span class="add-image-icon">+</span>
          <div class="painting-title">Add Painting</div>
          <div class="painting-description">Click to add a new artwork</div>
        </div>
      </div>

      <!-- Gallery Images -->
      <div
        class="painting"
        v-for="image in displayedPaintings"
        :key="image.id"
        @click="selectPainting(image)"
      >
        <div class="image-container">
          <img :src="image.imageUrls && image.imageUrls[0]" :alt="image.title" />
        </div>
        <div class="painting-info">
          <div class="painting-title">
            {{ image.title }}
          </div>
          <div class="price-display" v-if="!image.sold">
            <span 
              v-if="image.discount && isDiscountActive(image)" 
              class="original-price"
            >
              €{{ image.price }}
            </span>
            <span class="final-price">
              €{{ getPriceDisplay(image) }}
            </span>
            <span 
              v-if="image.discount && isDiscountActive(image)" 
              class="discount-badge"
            >
              -{{ image.discount }}%
            </span>
          </div>
          <div v-else class="sold-badge">
            Sold
          </div>
          <div class="painting-details">
            <div class="painting-column">
              <div class="painting-artist">{{ image.artistName }}</div>
              <div class="painting-year">{{ image.year }}</div>
            </div>
            <div class="painting-column">
              <div class="painting-medium">{{ image.medium }}</div>
              <div class="painting-dimensions">{{ image.dimensions }}</div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Painting Modal -->
    <PaintingModal
      v-if="showModal"
      :painting="selectedPainting"
      :user="user"
      @close="closeModal"
      @save="updatePainting"
      @add="addPainting"
      @delete="deletePainting"
    />
  </div>
</template>

<script>
import { ref, computed, onMounted, watch } from 'vue';
import { useStore } from 'vuex';
import PaintingModal from './PaintingModal.vue';
import FilterSortControls from './FilterSortControls.vue';
import { useRouter } from 'vue-router';

export default {
  name: 'ImageGallery',
  props: {
    paintingId: String,
  },
  components: {
    PaintingModal,
    FilterSortControls,
  },
  setup(props) {
    const store = useStore();
    const selectedPainting = ref(null);
    const showModal = ref(false);
    const displayedPaintings = ref([]);
    const isLoading = ref(true);
    const router = useRouter();

    const user = computed(() => store.getters.user);
    const paintings = computed(() => store.getters.paintings);

    onMounted(async () => {
      await store.dispatch('fetchPaintings');
      isLoading.value = false;
    });

    watch(paintings, (newPaintings) => {
      displayedPaintings.value = newPaintings;
    }, { immediate: true });

    watch(
      [paintings, () => props.paintingId],
      ([newPaintings, newPaintingId]) => {
        if (newPaintingId && newPaintings.length > 0) {
          const painting = newPaintings.find(p => p.id === newPaintingId);
          if (painting) {
            selectPainting(painting);
          }
        }
      },
      { immediate: true }
    );

    function selectPainting(painting) {
      selectedPainting.value = painting;
      showModal.value = true;
      if (!props.paintingId) {
        router.replace({ path: `/gallery/${painting.id}` });
      }
    }

    function openAddModal() {
      selectedPainting.value = null;
      showModal.value = true;
    }

    function closeModal() {
      showModal.value = false;
      selectedPainting.value = null;
      if (props.paintingId) {
        router.replace({ path: '/gallery' });
      }
    }

    function updatePainting(updatedData) {
      store.dispatch('updatePainting', updatedData);
    }

    function deletePainting(paintingId) {
      store.dispatch('deletePainting', paintingId);
    }

    function addPainting(newPainting) {
      store.dispatch('addPainting', newPainting);
    }

    function handleFilter(filteredPaintings) {
      displayedPaintings.value = filteredPaintings;
    }

    function handleSort(sortedPaintings) {
      displayedPaintings.value = sortedPaintings;
    }

    function isDiscountActive(painting) {
      if (!painting.discount || painting.discount <= 0) return false;
      
      const now = new Date().getTime();
      const start = painting.discountStart ? new Date(painting.discountStart).getTime() : null;
      const end = painting.discountEnd ? new Date(painting.discountEnd).getTime() : null;
      
      // If no dates are set, discount is always active
      if (!start && !end) return true;
      
      // If only start date is set, check if we're after start
      if (start && !end) return now >= start;
      
      // If only end date is set, check if we're before end
      if (!start && end) return now <= end;
      
      // If both dates are set, check if we're within range
      return now >= start && now <= end;
    }

    function getPriceDisplay(painting) {
      if (painting.sold) return 'Sold';
      
      const price = parseFloat(painting.price.replace(/\s/g, ''));
      const discount = parseFloat(painting.discount);
      
      if (discount && discount > 0 && isDiscountActive(painting)) {
        return (price * (1 - discount / 100)).toFixed(2);
      }
      
      return price.toFixed(2);
    }

    return {
      user,
      paintings,
      displayedPaintings,
      selectedPainting,
      showModal,
      isLoading,
      selectPainting,
      openAddModal,
      closeModal,
      updatePainting,
      deletePainting,
      addPainting,
      handleFilter,
      handleSort,
      getPriceDisplay,
      isDiscountActive,
    };
  },
};
</script>

<style scoped>
.gallery-container {
  margin: 0 auto;
  padding: 20px;
}

.gallery {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  gap: 30px;
}

.painting {
  background-color: #fff;
  border-radius: 12px;
  overflow: hidden;
  box-shadow: 0 6px 15px rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease;
  cursor: pointer;
}

.painting:hover {
  transform: translateY(-10px);
  box-shadow: 0 12px 30px rgba(0, 0, 0, 0.15);
}

.image-container {
  position: relative;
  padding-top: 100%; /* 1:1 Aspect Ratio */
  overflow: hidden;
}

.painting img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: transform 0.3s ease;
}

.painting:hover img {
  transform: scale(1.05);
}

.add-image-card {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #f9f9f9;
  border: 2px dashed #ccc;
  transition: all 0.3s ease;
}

.add-image-card:hover {
  background-color: #f0f0f0;
  border-color: #999;
}

.add-image-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  padding: 20px;
}

.add-image-icon {
  font-size: 3em;
  margin-bottom: 10px;
  color: #666;
}

.painting-info {
  padding: 15px;
  text-align: left;
}

.painting-title {
  font-weight: bold;
  font-size: 1.1em;
  margin-bottom: 10px;
  color: #333;
  display: flex;
  flex-wrap: wrap;
  gap: 5px;
}

.painting-details {
  display: flex;
  margin-bottom: 5px;
}

.painting-column {
  display: flex;
  flex-direction: column;
  margin-right: auto;
}

.painting-artist,
.painting-medium {
  font-size: 0.9em;
  color: #666;
  margin-bottom: 3px;
  text-align: left;
  font-weight: bold;
}

.painting-year,
.painting-dimensions {
  font-size: 0.9em;
  color: #666;
  margin-bottom: 3px;
  text-align: left;
}

@media (max-width: 768px) {
  .gallery {
    grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
    gap: 20px;
  }

  .gallery-title {
    font-size: 2em;
  }
}

.loading, .no-paintings {
  text-align: center;
  font-size: 1.2em;
  margin-top: 20px;
}

.discounted-price {
  color: #e41e31;  /* or any color you prefer for discounted prices */
}

.price-display {
  display: flex;
  align-items: center;
  gap: 8px;
  margin-bottom: 0.5rem;
}

.original-price {
  text-decoration: line-through;
  color: #999;
  font-size: 0.9em;
}

.final-price {
  color: #333;
  font-weight: bold;
}

.discount-badge {
  background-color: #e41e31;
  color: white;
  padding: 2px 6px;
  border-radius: 4px;
  font-size: 0.8em;
}

.discount-dates {
  display: flex;
  gap: 20px;
}

.date-range {
  flex: 1;
}

.date-range input {
  width: 100%;
}

.sold-badge {
  background-color: #333;
  color: white;
  padding: 5px 15px;
  border-radius: 4px;
  font-weight: bold;
  display: inline-block;
  margin-bottom: 10px;
}
</style>