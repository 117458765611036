<template>
  <section class="blog-section">
    <div class="blog-posts">
      <!-- Add New Post Card -->
      <div v-if="user" class="blog-post add-post-card" @click="openAddModal">
        <div class="add-post-content">
          <span class="add-post-icon">+</span>
          <div class="post-title">Add New Post</div>
          <div class="post-description">Click to add a new blog post</div>
        </div>
      </div>

      <!-- Blog Posts -->
      <article 
        v-for="post in blogPosts" 
        :key="post.id" 
        class="blog-post"
        @click="openEditModal(post)"
      >
        <h3>{{ post.title }}</h3>
        <p class="post-meta">{{ formatDate(post.date) }} | {{ post.author }}</p>
        <div class="blog-post-content" v-html="post.content"></div>
      </article>
    </div>

    <!-- Blog Post Modal -->
    <BlogPostModal
      v-if="showModal"
      :post="selectedPost"
      :user="user"
      @close="closeModal"
      @save="savePost"
    />
  </section>
</template>

<script>
import { ref, computed, onMounted } from 'vue';
import { useStore } from 'vuex';
import BlogPostModal from './BlogPostModal.vue';

export default {
  name: 'BlogSection',
  components: {
    BlogPostModal,
  },
  props: ['user'],
  setup(props) {  // Keep the props parameter
    const store = useStore();
    const showModal = ref(false);
    const selectedPost = ref(null);

    const blogPosts = computed(() => store.getters.blogPosts);

    onMounted(() => {
      store.dispatch('fetchBlogPosts');
    });

    const openAddModal = () => {
      selectedPost.value = null;
      showModal.value = true;
    };

    const openEditModal = (post) => {
      selectedPost.value = post;
      showModal.value = true;
    };

    const closeModal = () => {
      showModal.value = false;
      selectedPost.value = null;
    };

    const savePost = () => {
      store.dispatch('fetchBlogPosts');
      closeModal();
    };

    const formatDate = (timestamp) => {
      if (!timestamp || typeof timestamp.toDate !== 'function') return '';
      try {
        const date = timestamp.toDate();
        return date.toLocaleDateString();
      } catch (error) {
        console.error('Error formatting date:', error);
        return '';
      }
    };

    // Use props.user here to demonstrate its usage and satisfy ESLint
    const canAddPost = computed(() => !!props.user);

    return {
      blogPosts,
      showModal,
      selectedPost,
      openAddModal,
      openEditModal,
      closeModal,
      savePost,
      formatDate,
      canAddPost,  // Add this to the returned object
    };
  },
};
</script>

<style scoped>
.blog-section {
  margin: 0 auto;
  padding: 20px;
}

.blog-posts {
  display: grid;
  grid-template-columns: 1fr;
  gap: 30px;
}

.blog-post {
  background-color: #fff;
  border-radius: 12px;
  overflow: hidden;
  box-shadow: 0 6px 15px rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease;
  cursor: pointer;
  padding: 20px;
  display: flex;
  flex-direction: column;
  max-height: 400px; /* Add this line to limit the height */
}

.blog-post:hover {
  transform: translateY(-5px);
  box-shadow: 0 12px 30px rgba(0, 0, 0, 0.15);
}

.blog-post h3 {
  font-size: 1.5em;
  margin-bottom: 10px;
}

.post-meta {
  font-size: 0.9em;
  color: #666;
  margin-bottom: 15px;
}

.blog-post-content {
  flex-grow: 1;
  overflow: hidden; /* Add this line */
  position: relative; /* Add this line */
}

/* Add this new style for the fade effect */
.blog-post-content::after {
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  height: 50px;
  background: linear-gradient(transparent, white);
}

/* Remove this block */
.read-more {
  display: inline-block;
  margin-top: 15px;
  color: var(--accent-color);
  text-decoration: none;
  font-weight: bold;
}

.add-post-card {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #f9f9f9;
  border: 2px dashed #ccc;
  transition: all 0.3s ease;
  min-height: 200px;
}

.add-post-card:hover {
  background-color: #f0f0f0;
  border-color: #999;
}

.add-post-content {
  text-align: center;
}

.add-post-icon {
  font-size: 3em;
  margin-bottom: 10px;
  color: #666;
}

.post-title {
  font-weight: bold;
  font-size: 1.2em;
  margin-bottom: 5px;
  color: #333;
}

.post-description {
  font-size: 0.9em;
  color: #666;
}

/* New styles for images within blog posts */
:deep(.blog-post-content img) {
  max-width: 100%;
  height: auto;
  display: block;
  margin: 1em 0;
  border-radius: 8px; /* Optional: adds rounded corners to images */
}

@media (min-width: 768px) {
  .blog-posts {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (max-width: 767px) {
  .blog-posts {
    grid-template-columns: 1fr;
  }
}
</style>
