<template>
  <div class="how-to-buy">
    <!-- <section class="black-friday-offer">
      <h2>Black Friday Offer:</h2>
      <div class="offer-details">
        <p class="highlight">50% off paintings and gift cards from 29.11.2024 00:00 to 02.12.2024 23:59.</p>
        
        <h3>What to do:</h3>
        <ol>
          <li>
            Email me within the promotion period. Specify the painting or gift card amount and delivery method.
            <ul>
              <li>Paintings: Prices during the promotion are listed on the website.</li>
              <li>
                Gift Cards: Pay half the amount and receive a card worth double your payment.<br>
                <em>Example: Pay €50, receive a €100 gift card.</em>
              </li>
            </ul>
          </li>
          <li>I will send an invoice.</li>
          <li>Complete the payment.</li>
          <li>Once the payment is confirmed, you can use the gift card immediately. Cards are sent the same day or the next morning if payment is received after 6 PM.</li>
          <li>Paintings are prepared for shipping within three business days.</li>
        </ol>
      </div>
    </section> -->

    <section class="buying-process">
      <h2>How to Buy a Painting:</h2>
      <ol>
        <li>Choose a painting you like.</li>
        <li>
          Email me at elena.tezhe@gmail.com. In your email, specify:
          <ul>
            <li>The chosen painting</li>
            <li>Preferred delivery method</li>
            <li>Delivery address if choosing courier or postal delivery</li>
            <li>If you have a gift card and wish to use it, include its number and the amount you'd like to apply</li>
          </ul>
        </li>
        <li>I will send you an invoice.</li>
        <li>Complete the payment.</li>
        <li>Within three business days after payment, I will ship the painting or have it ready for pickup if you chose self-collection.</li>
        <li>Hang your new painting in its place! 😊</li>
      </ol>
      <p class="note"><em>I'd greatly appreciate it if you could send me a photo of the painting in its new home.</em></p>
    </section>

    <section class="delivery-options">
      <h2>Painting Delivery Options:</h2>
      <ul>
        <li>Self-collection: From Riga city center (Birznieka-Upisha Street, Riga, Latvia)</li>
        <li>Courier delivery: Anywhere in Latvia</li>
        <li>Worldwide shipping: Via Latvia Post or courier services</li>
      </ul>
    </section>

    <section class="packaging">
      <h2>Painting Packaging:</h2>
      <div class="packaging-options">
        <h3>For international delivery:</h3>
        <ul>
          <li>The painting is packaged in a tube or a box (depending on the buyer's choice)</li>
          <li>Boxed delivery costs 1.5-2 times more</li>
          <li>In a box: The painting remains stretched on its frame, with corner protectors, bubble wrap, and foam</li>
          <li>In a tube: The painting is removed from the frame, which is disassembled and shipped with the canvas in the tube. The buyer will need to reassemble the frame and stretch the canvas</li>
        </ul>

        <h3>For local delivery or self-collection:</h3>
        <ul>
          <li>Paintings are wrapped in bubble wrap unless the buyer requests a different type of packaging</li>
        </ul>

        <h3>Painting packaging example</h3>
        <div class="video-container">
          <iframe width="560" height="315" src="https://www.youtube.com/embed/i44XQ96ZceI?controls=0" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
        </div>
      </div>
    </section>

    <section class="delivery-costs">
      <h2>Delivery Costs:</h2>
      <ul>
        <li>Within Riga: Free courier delivery.</li>
        <li>
          Within Latvia:
          <ul>
            <li>For paintings under €400: €20.</li>
            <li>For paintings over €400: Free courier delivery.</li>
          </ul>
        </li>
        <li>Worldwide: Based on the chosen delivery method (courier or postal services). Confirmed before purchase.</li>
      </ul>
    </section>

    <section class="gift-card-delivery-costs">
      <h2>Gift Card Delivery Costs:</h2>
      <ul>
        <li>Courier delivery in Riga: Free.</li>
        <li>Omniva within Latvia, Estonia, and Lithuania: Free.</li>
        <li>Worldwide by post: Free.</li>
      </ul>
    </section>

    <section class="return-policy">
      <h2>Return Policy:</h2>
      <ol>
        <li>You may return a painting within 14 days. Contact me to arrange the return details.</li>
        <li>After the painting is returned, the money will be refunded to your account within five business days.</li>
        <li>To receive the refund, you must submit a free-form statement with your bank account details. Latvian residents can sign it electronically, or alternatively, it can be signed manually and scanned.</li>
      </ol>
    </section>

    <section class="gift-cards">
      <h2>Gift Cards:</h2>
      <ol>
        <li>
          You can order a gift card by:
          <ul>
            <li>Emailing me at elena.tezhe@gmail.com. Include the desired amount and the delivery address.</li>
            <li>Cards can be delivered via Omniva (free), courier, or self-collected in Riga.</li>
            <li>For international delivery, Latvia Post will be used.</li>
          </ul>
        </li>
        <li>I will send an invoice for payment.</li>
        <li>Once payment is received, the card will be sent on the same day or the next morning (if payment is received after 6 PM).</li>
        <li>Gift cards are valid for two years and can be used to purchase a painting or place a custom order.</li>
      </ol>
    </section>

    <section class="custom-painting-orders">
      <h2>Custom Painting Orders:</h2>
      <ol>
        <li>
          For custom orders, email me at elena.tezhe@gmail.com. Include:
          <ul>
            <li>The desired painting style.</li>
            <li>Reference images, dimensions, and preferred timeframe.</li>
          </ul>
        </li>
        <li>
          Minimum production time:
          <ul>
            <li>Oil paintings: 4 months.</li>
            <li>Acrylic paintings: 2 months.</li>
          </ul>
        </li>
      </ol>
    </section>

    <section class="custom-painting-process">
      <h2>Custom Painting Process:</h2>
      <ol>
        <li>Sketch preparation (up to 1 month for complex designs, depending on client feedback).</li>
        <li>Painting creation (1-2 months, up to 4 months for intricate pieces).</li>
        <li>
          Drying period:
          <ul>
            <li>Oil paintings: 1-2 months (thin layers); up to 5-6 months (textured).</li>
            <li>Acrylic paintings: Up to 5 days.</li>
          </ul>
        </li>
        <li>Lacquering: Typically, 3 coats of gloss lacquer (3-4 days for application and drying).</li>
        <li>
          Shipping:
          <ul>
            <li>Packaged and dispatched within three business days.</li>
            <li>Paintings are delivered stretched on a frame unless otherwise requested.</li>
          </ul>
        </li>
      </ol>
    </section>

    <section class="make-your-offer">
      <h2>Make your Offer:</h2>
      <p>You can make an offer for any available painting by emailing elena.tezhe@gmail.com. I will let you know if I accept your proposed price.</p>
    </section>

    <section class="company-details">
      <h2>Company Details:</h2>
      <div class="details">
        <p>Elizart SIA</p>
        <p>Registration No.: 40103522458</p>
        <p>Address: Ernesta Birznieka-Upīša iela 10A - 37, Riga, LV-1050, Latvia</p>
        <p>Email: Elizart.latvia@gmail.com</p>
      </div>
    </section>
  </div>
</template>

<script>
export default {
  name: 'HowToBuy',
  setup() {
    // Add any reactive properties or methods if needed
    return {};
  }
};
</script>

<style scoped>
.how-to-buy {
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
}

section {
  margin-bottom: 40px;
  background-color: var(--background-color);
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

h2 {
  color: var(--primary-color);
  margin-bottom: 20px;
  font-size: 1.8em;
}

h3 {
  color: var(--primary-color);
  margin: 15px 0;
  font-size: 1.3em;
}

ul, ol {
  padding-left: 20px;
  margin-bottom: 15px;
}

li {
  margin-bottom: 10px;
  line-height: 1.6;
}

.note {
  color: var(--text-color);
  font-style: italic;
  margin-top: 15px;
}

.details p {
  margin: 5px 0;
}

@media (max-width: 768px) {
  .how-to-buy {
    padding: 10px;
  }

  section {
    padding: 15px;
    margin-bottom: 20px;
  }

  h2 {
    font-size: 1.5em;
  }

  h3 {
    font-size: 1.2em;
  }
}

.black-friday-offer {
  border: 2px solid var(--accent-color);
  background-color: rgba(255, 215, 0, 0.05);
}

.highlight {
  font-size: 1.2em;
  font-weight: bold;
  color: var(--primary-color);
  margin-bottom: 20px;
}

.offer-details {
  padding: 10px 0;
}
</style>
