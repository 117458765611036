import { createApp } from 'vue';
import App from './App.vue';
import store from './store';
import router from './router'
import { getAuth, onAuthStateChanged } from 'firebase/auth'; // Import Firebase Auth

const auth = getAuth(); // Initialize Firebase Auth

onAuthStateChanged(auth, (user) => {
  if (user) {
    // User is signed in, update the Vuex store with user info
    store.dispatch('setUser', user);
  } else {
    // User is signed out, set user to null
    store.dispatch('setUser', null);
  }
});

createApp(App)
  .use(store)
  .use(router)
  .mount('#app');