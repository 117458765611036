import { createRouter, createWebHistory } from 'vue-router'
import LandingPage from '../components/LandingPage.vue'
import ImageGallery from '../components/ImageGallery.vue'
import BlogSection from '../components/BlogSection.vue'
import ContactPage from '../components/ContactPage.vue'
import MessageList from '../components/MessageList.vue'
import HowToBuy from '../components/HowToBuy.vue'

const routes = [
  {
    path: '/',
    name: 'Home',
    component: LandingPage
  },
  {
    path: '/gallery/:paintingId?',
    name: 'Gallery',
    component: ImageGallery,
    props: true
  },
  {
    path: '/blog',
    name: 'Blog',
    component: BlogSection
  },
  {
    path: '/contact',
    name: 'Contact',
    component: ContactPage
  },
  {
    path: '/messages',
    name: 'Messages',
    component: MessageList
  },
  {
    path: '/how-to-buy',
    name: 'HowToBuy',
    component: HowToBuy
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
