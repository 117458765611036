<template>
  <div v-if="show" class="modal-overlay" @click.self="$emit('close')">
    <div class="modal-content">
      <button class="close-btn" @click="closeModal">×</button>
      <h2>{{ user ? 'Logout' : 'Login' }}</h2>
      <form v-if="!user" @submit.prevent="handleSubmit">
        <div class="form-group">
          <label for="email">Email:</label>
          <input id="email" v-model="email" type="email" required>
        </div>
        <div class="form-group">
          <label for="password">Password:</label>
          <input id="password" v-model="password" type="password" required>
        </div>
        <button type="submit" class="submit-btn">Login</button>
      </form>
      <div v-else>
        <p>Are you sure you want to logout?</p>
        <button @click="handleLogout" class="submit-btn">Confirm Logout</button>
      </div>
    </div>
  </div>
</template>
  
  <script>
  import { ref } from 'vue';
  import { useStore } from 'vuex';
  
  export default {
    name: 'LoginModal',
    props: {
      show: Boolean,
      user: Object
    },
    emits: ['close', 'login', 'logout'],
    setup() {
      const store = useStore();
      const email = ref('');
      const password = ref('');
  
      return { email, password, store };
    },
    methods: {
      closeModal() {
        this.$emit('close');
      },
      async handleSubmit()  {
        try {
          this.$store.dispatch('login', { email: this.email, password: this.password });
          this.$emit('close');
        } catch (error) {
          console.error('Login error:', error);
          alert('Login failed. Please check your credentials and try again.');
        }
      },
      async handleLogout() {
        try {
          await this.store.dispatch('logout');
          this.$emit('close');
        } catch (error) {
          console.error('Logout error:', error);
          alert('Logout failed. Please try again.');
        }
      }
    },
  };
  </script>
  
  <style scoped>
  .modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
  }
  
  .modal-content {
    background-color: white;
    padding: 2rem;
    border-radius: 8px;
    width: 90%;
    max-width: 400px;
    box-shadow: 0 10px 25px rgba(0, 0, 0, 0.2);
    position: relative;
    max-height: 90vh;
    overflow: hidden;
    display: flex;
    flex-direction: column;
  }
 
  h2 {
    margin-top: 0;
    color: var(--primary-color);
  }
  
  .form-group {
    margin-bottom: 1rem;
  }
  
  label {
    display: block;
    margin-bottom: 0.5rem;
    color: var(--text-color);
  }
  
  input {
    width: 95%;
    padding: 0.5rem;
    border: 1px solid #ccc;
    border-radius: 4px;
  }
  
  .submit-btn {
    background-color: var(--accent-color);
    color: var(--primary-color);
    border: none;
    padding: 0.5rem 1rem;
    border-radius: 4px;
    cursor: pointer;
    font-weight: bold;
  }
  
  .submit-btn:hover {
    background-color: var(--secondary-color);
  }
  
  .close-btn {
    position: absolute;
    top: 15px;
    right: 15px;
    background: none;
    border: none;
    font-size: 2em;
    cursor: pointer;
    color: #333;
    z-index: 10;
  }
  </style>