<template>
  <div class="landing-page">
    <div class="about-section">
      <div class="profile-image-container">
        <img src="/elena.webp" alt="Elena Tezhe" class="profile-image">
      </div>
      <div class="about-content">
        <h2>About Me</h2>
        <p>My mission is to help people transform their surroundings into spaces that are more beautiful and joyful. I believe that art can inspire happiness, energy, and the confidence to turn dreams into reality.</p>
        
        <p>On this site, you can explore my paintings and purchase them through the online shop. You are also welcome to visit Elizart Gallery in Riga, Latvia, where you can enjoy my artwork in person along with a cup of coffee.</p>
        
        <p>Some of my paintings are available for purchase on <a href="https://www.saatchiart.com/elena.tezhe" target="_blank" rel="noopener noreferrer">Saatchi Art</a>.</p>
        
        <p>You can also rent my paintings. For additional information, please feel free to <router-link to="/contact">contact me</router-link>.</p>
      </div>
    </div>
    
    <h3>Exhibitions</h3>
    <ul>
      <li>June, 2018 - "Творить может каждый", Saint Petersburg, together with other artists</li>
      <li>August, 2019 - "Marketing has won", Zvaigzne Cafe, Riga, solo exhibition</li>
      <li>February, 2020 - "Infrared Dutch Landscapes", Zvaigzne Cafe, Riga, solo exhibition</li>
      <li>August, 2020 - Art Fair "Wings of Riga 2020", Spilve airport, Riga, Group exhibition</li>
      <li>August, 2022 - Art Fair "Wings of Riga 2022", Spilve airport, Riga, Group exhibition</li>
      <li>November, 2022 - January, 2023 - Exhibition "Winter Vernissage", Jurmala Outlet Village, Pinki, Group exhibition</li>
      <li>August, 2023 - Art Fair "Wings of Riga 2023", Spilve airport, Riga, Group exhibition</li>
      <li>August, 2024 - Art Fair "Wings of Riga 2024", Spilve airport, Riga, Group exhibition</li>
    </ul>
    
    <h3>Book Illustrations</h3>
    <ul>
      <li>March, 2020 - "Разноцветный дождь"</li>
      <li>September, 2021 - "Let's do our bit and leave it to earth to decide its ways…" for United Nations. Programs: Procreate, InDesign</li>
    </ul>
    
    <p>Basic knowledge in Augmented Reality (Unity&Vuforia)</p>
    
    <h3>Certificates</h3>
    <div class="certificate-images">
      <figure>
        <img src="/certificate-2018.webp" alt="Certificate 2018" class="certificate-img" @click="openModal('/certificate-2018.webp')">
        <figcaption>Luxembourg Art Prize 2018</figcaption>
      </figure>
      <figure>
        <img src="/certificate-2021.webp" alt="Certificate 2021" class="certificate-img" @click="openModal('/certificate-2021.webp')">
        <figcaption>Luxembourg Art Prize 2021</figcaption>
      </figure>
      <figure>
        <img src="/certificate-2022.webp" alt="Certificate 2022" class="certificate-img" @click="openModal('/certificate-2022.webp')">
        <figcaption>Luxembourg Art Prize 2022</figcaption>
      </figure>
      <figure>
        <img src="/certificate-2023.webp" alt="Certificate 2023" class="certificate-img" @click="openModal('/certificate-2023.webp')">
        <figcaption>Luxembourg Art Prize 2023</figcaption>
      </figure>
    </div>
    
    <!-- Modal for enlarged image -->
    <div v-if="modalOpen" class="modal" @click="closeModal">
      <img :src="modalImage" alt="Enlarged Certificate" class="modal-content" @click.stop>
    </div>
    
    <router-link to="/gallery" class="cta-button">View Gallery</router-link>
  </div>
</template>

<script>
import { ref } from 'vue';

export default {
  name: 'LandingPage',
  setup() {
    const modalOpen = ref(false);
    const modalImage = ref('');

    const openModal = (imageSrc) => {
      modalImage.value = imageSrc;
      modalOpen.value = true;
    };

    const closeModal = () => {
      modalOpen.value = false;
    };

    return {
      modalOpen,
      modalImage,
      openModal,
      closeModal
    };
  }
}
</script>

<style scoped>
.landing-page {
  text-align: left;
  padding: 40px 20px;
  max-width: 800px;
  margin: 0 auto;
}

.about-section {
  display: flex;
  align-items: center; /* This centers items vertically */
  gap: 40px;
  margin-bottom: 40px;
}

.profile-image-container {
  flex-shrink: 0;
  display: flex;
  align-items: center; /* This ensures the image is centered within its container */
}

.profile-image {
  width: 250px;
  height: 250px;
  object-fit: cover;
  border-radius: 50%;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease;
}

.profile-image:hover {
  transform: scale(1.05);
}

.about-content {
  flex-grow: 1;
}

h1, h2, h3 {
  margin-bottom: 20px;
}

h1 {
  font-size: 2.5em;
  text-align: center;
}

h2 {
  font-size: 2em;
}

h3 {
  font-size: 1.5em;
  margin-top: 30px;
}

p {
  font-size: 1.1em;
  margin-bottom: 15px;
  line-height: 1.6;
}

ul {
  margin-bottom: 20px;
  padding-left: 20px;
}

li {
  margin-bottom: 10px;
}

.cta-button {
  display: inline-block;
  background-color: var(--accent-color);
  color: var(--primary-color);
  padding: 12px 24px;
  border-radius: 25px;
  text-decoration: none;
  font-weight: bold;
  transition: background-color 0.3s ease;
  margin-top: 30px;
}

.cta-button:hover {
  background-color: var(--secondary-color);
}

.certificate-images {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  margin-bottom: 20px;
}

.certificate-img {
  width: 200px;
  height: 150px;
  object-fit: cover;
  margin: 10px;
  border: 1px solid #ddd;
  border-radius: 4px;
  cursor: pointer;
  transition: transform 0.3s ease;
}

.certificate-img:hover {
  transform: scale(1.05);
}

.modal {
  position: fixed;
  z-index: 1000;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0,0,0,0.9);
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal-content {
  max-width: 90%;
  max-height: 90%;
  object-fit: contain;
}

@media (max-width: 768px) {
  .about-section {
    flex-direction: column;
    align-items: center;
  }

  .profile-image {
    width: 200px;
    height: 200px;
    margin-bottom: 20px;
  }

  .certificate-img {
    width: 150px;
    height: 112px;
  }
}
</style>
