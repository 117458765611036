<template>
  <div id="app">
    <!-- Header with Background Image and Login Button -->
    <header>
      <!-- <h1>Elena Tezhe</h1> -->
      <a href="#" class="login-btn" @click.prevent="showLoginModal = true">
        {{ user ? 'Logout' : 'Login' }}
      </a>
    </header>

    <!-- Hamburger Menu Button -->
    <button class="hamburger" @click="toggleNav">☰</button>

    <!-- Navigation Bar -->
    <nav :class="{ open: isNavOpen }">
      <ul>
        <li><router-link to="/">Home</router-link></li>
        <li><router-link to="/gallery">Gallery</router-link></li>
        <li><router-link to="/how-to-buy">How to Buy</router-link></li>
        <li><router-link to="/blog">Blog</router-link></li>
        <li><router-link to="/contact">Contact</router-link></li>
        <li v-if="user">
          <router-link to="/messages">Messages</router-link></li>
      </ul>
    </nav>

    <!-- Main Container -->
    <div class="container">
      <!-- Add the Login Modal -->
      <LoginModal 
        :show="showLoginModal"
        :user="user"
        @close="showLoginModal = false"
        @login="handleLogin"
        @logout="logout"
      />
      <!-- Router View Component -->
      <router-view :user="user"></router-view>
    </div>
  </div>
</template>

<script>
import { computed, ref, onMounted } from 'vue';
import { useStore } from 'vuex';
import LoginModal from './components/LoginModal.vue';

export default {
  components: {
    LoginModal,
  },
  setup() {
    const store = useStore();
    const user = computed(() => store.getters.user);
    const showLoginModal = ref(false);
    const isNavOpen = ref(false);

    onMounted(() => {
      // Initialize user state
      user.value = store.state.user;
    });

    // Method to handle login from modal
    const handleLogin = async (credentials) => {
      try {
        await store.dispatch('login', credentials); // Dispatch the login action with credentials
        showLoginModal.value = false; // Close the modal on successful login
      } catch (error) {
        console.error('Error during login:', error);
        alert('Login failed. Please try again.');
      }
    };
    
    const logout = async () => {
      await store.dispatch('logout');
    };

    const toggleNav = () => {
      isNavOpen.value = !isNavOpen.value;
    };

    return {
      user,
      showLoginModal,
      handleLogin,
      logout,
      isNavOpen,
      toggleNav,
    };
  },
};
</script>

<style>
:root {
  --primary-color: #3a3a3a;
  --secondary-color: #f0f0f0;
  --accent-color: #ffd700;
  --text-color: #333;
  --background-color: #ffffff;
}

body {
  font-family: 'Roboto', Arial, sans-serif;
  margin: 0;
  padding: 0;
  background-color: var(--background-color);
  color: var(--text-color);
}

header {
  background-size: cover;
  background-position: center;
  color: white;
  text-align: center;
  padding: 100px 0;
  position: relative;
}

@media (max-width: 768px) {
  header {
    background-image: url('/public/header-image-small.webp');
  }
}

@media (min-width: 769px) {
  header {
    background-image: url('/public/header-image.webp');
  }
}

h1 {
  margin: 0;
  font-size: 3.5em;
  font-weight: 700;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.7);
  animation: fadeInUp 1s ease-out;
}

@keyframes fadeInUp {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.hamburger {
  display: none;
  font-size: 2em;
  background: rgba(0, 0, 0, 0.5);
  border: none;
  color: white;
  position: absolute;
  top: 20px;
  left: 20px;
  cursor: pointer;
  padding: 5px 15px;
  border-radius: 5px;
  backdrop-filter: blur(2px);
  z-index: 1000;
}

.hamburger:hover {
  background: rgba(0, 0, 0, 0.7);
}

nav {
  background-color: var(--primary-color);
  padding: 15px 0;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}

nav ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
  display: flex;
  justify-content: center;
  padding: 0 20px; /* Add some padding to the nav bar */
}

nav ul li {
  margin: 0 15px;
}

nav ul li a {
  color: var(--secondary-color);
  text-decoration: none;
  font-weight: 500;
  font-size: 1.1em;
  transition: all 0.3s ease;
  padding: 5px 10px;
  border-radius: 5px;
}

nav ul li a:hover {
  color: var(--accent-color);
  background-color: rgba(255, 255, 255, 0.1);
}

.login-btn {
  position: absolute;
  top: 20px;
  right: 20px;
  background-color: var(--accent-color);
  color: var(--primary-color);
  padding: 8px 15px;
  border-radius: 20px;
  text-decoration: none;
  font-weight: bold;
  transition: all 0.3s ease;
}

.login-btn:hover {
  background-color: var(--secondary-color);
  color: var(--primary-color);
}

.container {
  margin: 0 2rem;
  background-color: var(--background-color);
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.05);
  border-radius: 10px;
  margin-top: 40px;
}

@media (max-width: 768px) {
  h1 {
    font-size: 2.5em;
  }

  nav ul {
    flex-direction: column;
    align-items: center;
    display: none;
  }

  nav.open ul {
    display: flex;
  }

  nav ul li {
    margin: 10px 0;
  }

  .container {
    padding: 20px;
    margin-top: 20px;
    margin-left: 2rem;
    margin-right: 2rem;
  }

  .login-btn {
    top: 10px;
    right: 10px;
    font-size: 0.9em;
    padding: 6px 12px;
  }

  .hamburger {
    display: block;
  }
}

/* Add some spacing between sections */
.container > * {
  margin-bottom: 40px;
}
</style>