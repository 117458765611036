<template>
  <div class="modal-overlay" @click.self="closeModal">
    <div class="modal-content">
      <button class="close-btn" @click="closeModal" aria-label="Close modal">×</button>

      <div class="modal-body">
        <!-- Image Section -->
        <div class="painting-full">
          <div 
            class="image-container"
            @click="triggerFileInput"
          >
            <img 
              v-if="currentMainImage" 
              :src="currentMainImage" 
              :alt="editableTitle || 'Painting'"
            />
            <div v-else class="image-placeholder">
              <span>Click to Upload Image</span>
            </div>
          </div>
          <input 
            type="file" 
            ref="fileInput" 
            style="display: none;" 
            @change="onFileChange"
            accept="image/*"
            multiple
          />
          <!-- Thumbnails Section -->
          <div class="thumbnails-container">
            <div 
              v-for="(img, index) in editableImageUrls" 
              :key="index" 
              class="thumbnail-wrapper"
            >
              <img 
                :src="img.preview" 
                :alt="`Thumbnail ${index + 1}`"
                @click="setMainImage(index)"
                :class="{ 'active': index === currentMainImageIndex }"
              />
              <button 
                v-if="!isViewMode"
                class="delete-thumbnail-btn" 
                @click.stop="deleteThumbnail(index)" 
                aria-label="Delete thumbnail"
              >
                ×
              </button>
            </div>
            <!-- Add dummy thumbnail for uploading new image -->
            <div
              v-if="!isViewMode" 
              class="dummy-thumbnail"
              @click="triggerFileInput"
            >
              <span>+</span>
            </div>
          </div>
        </div>

        <!-- Form Section -->
        <div class="painting-info">
          <h2 class="painting-title">
            <div>
              {{ isViewMode ? `${painting.title}` : (painting ? 'Edit Painting' : 'Add New Painting') }}
            </div>
            <div v-if="isViewMode">
              by {{ painting.artistName }}
            </div>
          </h2>
          <form @submit.prevent="savePainting" v-if="!isViewMode">
            <div class="form-group">
              <label for="title">Title:</label>
              <input id="title" v-model="editableTitle" required />
            </div>

            <div class="form-group">
              <label for="artistName">Artist:</label>
              <input id="artistName" v-model="editableArtistName" required />
            </div>

            <div class="form-group">
              <label for="year">Year:</label>
              <input id="year" v-model="editableYear" type="number" required />
            </div>

            <div class="form-group">
              <label for="dimensions">Dimensions:</label>
              <input id="dimensions" v-model="editableDimensions" required />
            </div>

            <div class="form-group">
              <label for="medium">Medium:</label>
              <input id="medium" v-model="editableMedium" required />
            </div>

            <div class="form-group">
              <label for="style">Style:</label>
              <input id="style" v-model="editableStyle" required />
            </div>

            <div class="form-group">
              <label for="subject">Subject:</label>
              <textarea id="subject" v-model="editableSubject" required></textarea>
            </div>

            <div class="form-group">
              <label for="price">Price:</label>
              <input id="price" v-model="editablePrice" required />
            </div>

            <div class="form-group">
              <label for="discount">Discount (%):</label>
              <input 
                id="discount" 
                v-model.number="editableDiscount" 
                type="number" 
                min="0" 
                max="100" 
                placeholder="Enter discount percentage"
              />
            </div>

            <div class="form-group discount-dates" v-if="Number(editableDiscount) > 0">
              <div class="date-range">
                <label for="discountStart">Discount Start Date:</label>
                <input 
                  id="discountStart" 
                  v-model="editableDiscountStart" 
                  type="datetime-local"
                />
              </div>
              <div class="date-range">
                <label for="discountEnd">Discount End Date:</label>
                <input 
                  id="discountEnd" 
                  v-model="editableDiscountEnd" 
                  type="datetime-local"
                  :min="editableDiscountStart"
                />
              </div>
            </div>

            <div class="form-group">
              <label for="provenance">Description:</label>
              <textarea id="provenance" v-model="editableProvenance"></textarea>
            </div>

            <div class="form-group">
              <label for="conditionReport">Condition Report:</label>
              <textarea id="conditionReport" v-model="editableConditionReport"></textarea>
            </div>

            <div class="form-group">
              <label for="certificateOfAuthenticity">Certificate of Authenticity:</label>
              <input id="certificateOfAuthenticity" type="checkbox" v-model="editableCertificateOfAuthenticity" />
            </div>

            <div class="form-group">
              <label for="sold">Sold:</label>
              <input id="sold" type="checkbox" v-model="editableSold" />
            </div>

            <div class="button-group">
              <button type="submit" class="save-btn">
                {{ painting ? 'Save Changes' : 'Add Painting' }}
              </button>
              <button v-if="painting" @click.prevent="deletePainting" class="delete-btn">
                Delete Painting
              </button>
            </div>
          </form>
          <div v-else class="painting-details">
            <div class="detail-group primary-details">
              <div class="detail-item">
                <span class="label">Year</span>
                <span class="value">{{ editableYear }}</span>
              </div>
              <div class="detail-item">
                <span class="label">Dimensions</span>
                <span class="value">{{ editableDimensions }}</span>
              </div>
              <div class="detail-item">
                <span class="label">Medium</span>
                <span class="value">{{ editableMedium }}</span>
              </div>
            </div>

            <div class="detail-group price-section">
              <div class="price-content">
                <span class="label">Price</span>
                <div v-if="!editableSold">
                  <div class="price-tag" :class="{ 'has-discount': isDiscountActive }">
                    <span class="original-price" :class="{ 'strikethrough': isDiscountActive }">
                      {{ editablePrice }}
                    </span>
                    <span v-if="isDiscountActive" class="discounted-price">
                      {{ calculateDiscountedPrice }}
                      <span class="discount-badge">-{{ editableDiscount }}%</span>
                    </span>
                  </div>
                </div>
                <span class="sold-badge" v-if="editableSold">Sold</span>
              </div>
            </div>

            <div class="detail-group artwork-details">
              <div class="detail-item">
                <span class="label">Style</span>
                <span class="value">{{ editableStyle }}</span>
              </div>
              <div class="detail-item">
                <span class="label">Subject</span>
                <span class="value">{{ editableSubject }}</span>
              </div>
            </div>

            <div v-if="editableProvenance" class="detail-group description">
              <h3>Description</h3>
              <p>{{ editableProvenance }}</p>
            </div>

            <div class="detail-group additional-info">
              <div v-if="editableConditionReport" class="detail-item full-width">
                <h3>Condition Report</h3>
                <p>{{ editableConditionReport }}</p>
              </div>
              
              <div class="certification">
                <span class="cert-icon" :class="{ 'certified': editableCertificateOfAuthenticity }">
                  {{ editableCertificateOfAuthenticity ? '✓' : '×' }}
                </span>
                <span>Certificate of Authenticity</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { storage, db } from '../firebase';
import { ref as storageRef, deleteObject } from 'firebase/storage';
import { deleteDoc, doc } from 'firebase/firestore';

export default {
  name: 'PaintingModal',
  props: ['painting', 'user'],
  data() {
    return {
      editableTitle: '',
      editableArtistName: '',
      editableYear: '',
      editableDimensions: '',
      editableMedium: '',
      editableStyle: '',
      editableSubject: '',
      editablePrice: '',
      editableProvenance: '',
      editableConditionReport: '',
      editableCertificateOfAuthenticity: false,
      editableImageUrls: [],
      currentMainImageIndex: 0,
      editableSold: false,
      editableDiscount: 0,
      editableDiscountStart: '',
      editableDiscountEnd: '',
    };
  },
  computed: {
    currentMainImage() {
      return this.editableImageUrls[this.currentMainImageIndex]?.preview || '';
    },
    isViewMode() {
      return !this.user;
    },
    calculateDiscountedPrice() {
      const price = parseFloat(this.editablePrice.replace(/\s/g, ''));
      const currencySymbol = this.editablePrice.replace(/[0-9.-]+/g,"");
      const discount = parseFloat(this.editableDiscount);
      if (!isNaN(price) && !isNaN(discount) && discount > 0) {
        const discountedPrice = price * (1 - discount / 100);
        return (discountedPrice.toFixed(2) + currencySymbol).trim();
      }
      return this.editablePrice;
    },
    isDiscountActive() {
      if (!this.editableDiscount || this.editableDiscount <= 0) return false;
      if (!this.editableDiscountStart || !this.editableDiscountEnd) return true;
      
      const now = new Date();
      const startDate = new Date(this.editableDiscountStart);
      const endDate = new Date(this.editableDiscountEnd);
      
      return now >= startDate && now <= endDate;
    },
  },
  watch: {
    painting: {
      immediate: true,
      handler(newPainting) {
        if (newPainting) {
          this.initializeFormWithPaintingData();
        } else {
          this.resetForm();
        }
      }
    }
  },
  methods: {
    initializeFormWithPaintingData() {
      const fields = [
        'title', 'artistName', 'year', 'dimensions', 'medium', 'style', 
        'subject', 'price', 'provenance', 'conditionReport', 'certificateOfAuthenticity', 'discount'
      ];
      fields.forEach(field => {
        this[`editable${field.charAt(0).toUpperCase() + field.slice(1)}`] = this.painting[field];
      });
      this.editableImageUrls = this.painting.imageUrls.map(url => ({ preview: url }));
      this.editableSold = this.painting.sold || false;
      this.editableDiscountStart = this.painting.discountStart || '';
      this.editableDiscountEnd = this.painting.discountEnd || '';
    },
    resetForm() {
      const fields = [
        'title', 'artistName', 'year', 'dimensions', 'medium', 'style', 
        'subject', 'price', 'provenance', 'conditionReport', 'certificateOfAuthenticity'
      ];
      fields.forEach(field => {
        this[`editable${field.charAt(0).toUpperCase() + field.slice(1)}`] = '';
      });
      this.editableCertificateOfAuthenticity = false;
      this.editableImageUrls = [];
      this.editableSold = false;
      this.editableDiscountStart = '';
      this.editableDiscountEnd = '';
    },
    closeModal() {
      this.$emit('close');
    },
    triggerFileInput() {
      this.$refs.fileInput.click();
    },
    async onFileChange(event) {
      const files = event.target.files;
      if (files.length > 0) {
        for (let i = 0; i < files.length; i++) {
          const file = files[i];
          const filePreview = URL.createObjectURL(file);
          this.editableImageUrls.push({
            preview: filePreview,
            file: file
          });
        }
        this.currentMainImageIndex = this.editableImageUrls.length - 1;
      }
    },
    async savePainting() {
      const paintingData = {
        title: this.editableTitle,
        artistName: this.editableArtistName,
        year: this.editableYear,
        dimensions: this.editableDimensions,
        medium: this.editableMedium,
        style: this.editableStyle,
        subject: this.editableSubject,
        price: this.editablePrice,
        provenance: this.editableProvenance,
        conditionReport: this.editableConditionReport,
        certificateOfAuthenticity: this.editableCertificateOfAuthenticity,
        imageUrls: this.editableImageUrls.map(img => img.preview || img),
        sold: this.editableSold,
        discount: this.editableDiscount,
        discountStart: this.editableDiscountStart,
        discountEnd: this.editableDiscountEnd,
      };

      if (this.painting) {
        paintingData.id = this.painting.id;
      }

      // Pass the actual File objects along with the painting data
      const fileObjects = this.editableImageUrls
        .filter(img => img.file instanceof File)
        .map(img => img.file);

      if (this.painting) {
        this.$emit('save', { painting: paintingData, fileObjects });
      } else {
        this.$emit('add', { painting: paintingData, fileObjects });
      }

      this.closeModal();
    },
    deletePainting() {
      if (confirm('Are you sure you want to delete this painting?')) {
        const paintingRef = doc(db, 'images', this.painting.id);
        deleteDoc(paintingRef)
          .then(() => {
            const imageRef = storageRef(storage, `images/${this.painting.fileName}`);
            deleteObject(imageRef)
              .then(() => {
                this.$emit('delete', this.painting.id);
                this.closeModal();
              })
              .catch((error) => {
                console.error('Error deleting image file:', error);
              });
          })
          .catch((error) => {
            console.error('Error deleting painting metadata:', error);
          });
      }
    },
    setMainImage(index) {
      this.currentMainImageIndex = index;
    },
    deleteThumbnail(index) {
      if (confirm('Are you sure you want to delete this image?')) {
        this.editableImageUrls.splice(index, 1);
        if (this.currentMainImageIndex >= this.editableImageUrls.length) {
          this.currentMainImageIndex = this.editableImageUrls.length - 1;
        }
      }
    },
  },
};
</script>

<style scoped>
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modal-content {
  background-color: #fff;
  border-radius: 12px;
  box-shadow: 0 10px 25px rgba(0, 0, 0, 0.2);
  position: relative;
  width: 90%;
  max-width: 1200px;
  max-height: 90vh;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  max-height: 90vh;
}

.close-btn {
  position: absolute;
  top: 15px;
  right: 15px;
  background: none;
  border: none;
  font-size: 2em;
  cursor: pointer;
  color: #333;
  z-index: 10;
}

.modal-body {
  display: flex;
  flex-direction: row;
  height: calc(90vh - 30px);
  overflow: hidden;
}

.painting-full {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 20px;
  max-width: 50%;
}

.image-container {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  position: relative;
}

.image-container img {
  max-width: 100%;
  max-height: 100%;
  object-fit: contain;
}

.thumbnails-container {
  display: flex;
  justify-content: center;
  gap: 10px;
  margin-top: 15px;
  flex-wrap: wrap;
}

.thumbnail-wrapper {
  position: relative;
  width: 60px;
  height: 60px;
}

.thumbnails-container img {
  width: 60px;
  height: 60px;
  object-fit: cover;
  border: 2px solid transparent;
  border-radius: 4px;
  cursor: pointer;
  transition: border-color 0.3s ease;
}

.thumbnails-container img:hover,
.thumbnails-container img.active {
  border-color: #4CAF50;
}

.delete-thumbnail-btn {
  position: absolute;
  top: -8px;
  right: -8px;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background-color: #f44336;
  color: white;
  border: none;
  font-size: 14px;
  line-height: 1;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;
}

.delete-thumbnail-btn:hover {
  background-color: #d32f2f;
}

.image-placeholder {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #999;
  font-size: 1.2em;
  text-align: center;
}

.painting-info {
  flex: 1;
  padding: 30px;
  overflow-y: auto;
  max-width: 50%;
}

.painting-title {
  margin-top: 0;
  margin-bottom: 20px;
  font-size: 1.8em;
  color: #333;
}

.form-group {
  margin-bottom: 20px;
}

.form-group label {
  display: block;
  margin-bottom: 5px;
  font-weight: bold;
  color: #555;
}

.form-group input,
.form-group textarea {
  width: 100%;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 1em;
}

.checkbox {
  display: flex;
  align-items: center;
}

.checkbox input {
  margin-right: 10px;
}

.button-group {
  display: flex;
  justify-content: flex-start;
  margin-top: 30px;
}

.save-btn,
.delete-btn {
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  font-size: 1em;
  cursor: pointer;
  transition: background-color 0.3s ease, transform 0.1s ease;
}

.save-btn {
  background-color: #4CAF50;
  color: white;
}

.delete-btn {
  background-color: #f44336;
  color: white;
  margin-left: 10px;
}

.save-btn:hover,
.delete-btn:hover {
  transform: translateY(-2px);
}

.save-btn:hover {
  background-color: #45a049;
}

.delete-btn:hover {
  background-color: #da190b;
}

.dummy-thumbnail {
  width: 60px;
  height: 60px;
  border: 2px dashed #ccc;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  font-size: 24px;
  color: #ccc;
  transition: all 0.3s ease;
}

.dummy-thumbnail:hover {
  border-color: #999;
  color: #999;
}

.painting-details {
  padding: 20px;
}

.detail-group {
  margin-bottom: 30px;
  padding-bottom: 20px;
  border-bottom: 1px solid #eee;
}

.detail-group:last-child {
  border-bottom: none;
}

.title-group h1 {
  font-size: 2.2em;
  margin: 0;
  color: #333;
}

.title-group h2 {
  font-size: 1.4em;
  margin: 5px 0 0;
  color: #666;
  font-weight: normal;
}

.primary-details {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
}

.detail-item {
  flex: 1;
  min-width: 150px;
}

.detail-item.full-width {
  flex: 0 0 100%;
}

.label {
  display: block;
  font-size: 0.9em;
  color: #666;
  margin-bottom: 5px;
  text-transform: uppercase;
  letter-spacing: 0.5px;
}

.value {
  font-size: 1.1em;
  color: #333;
}

.price-section {
  display: block;
}

.price-content {
  margin-top: 5px;
}

.price-tag {
  font-size: 1.8em;
  font-weight: bold;
  color: #333;
}

.price-tag.has-discount .original-price {
  font-size: 0.8em;
  color: #999;
}

.strikethrough {
  text-decoration: line-through;
}

.discounted-price {
  color: #e53935;
  margin-left: 10px;
}

.discount-badge {
  background-color: #e53935;
  color: white;
  padding: 2px 8px;
  border-radius: 12px;
  font-size: 0.6em;
  margin-left: 8px;
  vertical-align: middle;
}

.sold-badge {
  display: inline-block;
  background-color: #333;
  color: white;
  padding: 5px 15px;
  border-radius: 4px;
  font-weight: bold;
}

.description, .additional-info {
  line-height: 1.6;
}

.description h3, .additional-info h3 {
  color: #333;
  margin-bottom: 10px;
}

.certification {
  display: flex;
  align-items: center;
  gap: 10px;
  margin-top: 20px;
}

.cert-icon {
  width: 24px;
  height: 24px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  background-color: #f44336;
  color: white;
}

.cert-icon.certified {
  background-color: #4CAF50;
}

.artwork-details {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
}

.discount-dates {
  display: flex;
  gap: 20px;
}

.date-range {
  flex: 1;
}

.date-range input {
  width: 100%;
}

@media (max-width: 768px) {
  .modal-body {
    flex-direction: column;
  }

  .painting-full,
  .painting-info {
    max-width: 100%;
  }

  .painting-full {
    height: 50vh;
  }

  .painting-info {
    height: calc(40vh - 30px);
  }

  .primary-details {
    flex-direction: column;
    gap: 15px;
  }

  .detail-item {
    min-width: 100%;
  }

  .price-tag {
    font-size: 1.5em;
  }

  .title-group h1 {
    font-size: 1.8em;
  }

  .title-group h2 {
    font-size: 1.2em;
  }
}
</style>