<template>
  <div class="contact-page">
    <h2>Contact Us</h2>
    <form @submit.prevent="submitForm" class="contact-form">
      <div class="form-group">
        <label for="name">Name:</label>
        <input type="text" id="name" v-model="name" required>
      </div>
      <div class="form-group">
        <label for="email">Email:</label>
        <input type="email" id="email" v-model="email" required>
      </div>
      <div class="form-group">
        <label for="message">Message:</label>
        <textarea id="message" v-model="message" required></textarea>
      </div>
      <button type="submit" class="submit-btn" :disabled="isSubmitting">
        {{ isSubmitting ? 'Sending...' : 'Send Message' }}
      </button>
    </form>
  </div>
</template>

<script>
import { ref } from 'vue';
import { useStore } from 'vuex';

export default {
  name: 'ContactPage',
  setup() {
    const store = useStore();
    const name = ref('');
    const email = ref('');
    const message = ref('');
    const isSubmitting = ref(false);

    const submitForm = async () => {
      isSubmitting.value = true;
      try {
        const messageData = {
          name: name.value,
          email: email.value,
          message: message.value,
        };
        await store.dispatch('sendMessage', messageData);
        alert('Thank you for your message! We will get back to you soon.');
        // Reset form fields
        name.value = '';
        email.value = '';
        message.value = '';
      } catch (error) {
        console.error('Error submitting form:', error);
        alert('There was an error sending your message. Please try again.');
      } finally {
        isSubmitting.value = false;
      }
    };

    return {
      name,
      email,
      message,
      submitForm,
      isSubmitting,
    };
  }
};
</script>

<style scoped>
.contact-page {
  max-width: 600px;
  margin: 0 auto;
  padding: 20px;
}

h2 {
  text-align: center;
  margin-bottom: 20px;
}

.contact-form {
  display: flex;
  flex-direction: column;
}

.form-group {
  margin-bottom: 20px;
  width: 100%;
}

label {
  display: block;
  margin-bottom: 5px;
}

input, textarea {
  width: 100%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
}

textarea {
  height: 150px;
}

.submit-btn {
  background-color: var(--accent-color);
  color: var(--primary-color);
  padding: 8px 15px;
  border-radius: 20px;
  border: none;
  cursor: pointer;
  font-weight: bold;
  font-size: 16px;
  transition: all 0.3s ease;
}

.submit-btn:hover {
  background-color: var(--secondary-color);
  color: var(--primary-color);
}

.submit-btn:disabled {
  opacity: 0.7;
  cursor: not-allowed;
}
</style>
