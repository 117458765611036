import { initializeApp } from 'firebase/app';
import { getAuth, connectAuthEmulator } from 'firebase/auth';
import { getFirestore, connectFirestoreEmulator } from 'firebase/firestore';
import { getStorage, connectStorageEmulator } from 'firebase/storage';

// Your web app's Firebase configuration
const firebaseConfig = {
    apiKey: "AIzaSyAqWy57TC5lqnzZKoK81uuq_7iq137AOhI",
    authDomain: "elenatezhe-8eab8.firebaseapp.com",
    projectId: "elenatezhe-8eab8",
    storageBucket: "elenatezhe-8eab8.appspot.com",
    messagingSenderId: "248948185888",
    appId: "1:248948185888:web:46fd86afb7ec5cd5b95545"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

// Initialize Firebase services
const auth = getAuth(app);
const db = getFirestore(app);
const storage = getStorage(app);

// Connect Firebase services to local emulators
if (window.location.hostname === "127.0.0.1") {
    connectAuthEmulator(auth, "http://127.0.0.1:9099");
    connectFirestoreEmulator(db, "127.0.0.1", 8080);
    connectStorageEmulator(storage, "127.0.0.1", 9199);
  }

export { auth, db, storage };